<template>
  <div
    class="alert"
    :class="`alert--${type}`"
    :style="{
      '--fillColor': `${ICON_BY_TYPE[type].color}`
    }"
  >
    <icon
      :name="ICON_BY_TYPE[type].icon"
      :fill="ICON_BY_TYPE[type].color"
      class="alert__icon"
    />
    <p
      v-if="value"
      class="alert__text"
    >{{ value }}</p>
  </div>
</template>


<script type="ts">
import { defineComponent } from 'vue';
import CONSTANTS from '@/utils/constants';
import Icon from '@/components/Icon.vue'

const ICON_BY_TYPE = {
  [CONSTANTS.ALERT_TYPES.ERROR]: { icon: 'warn', color: '#fff' }
};

export default defineComponent({
  name: 'Alert',
  components: { Icon },
  props: {
    type: {
      type: String,
      validator: s => Object.values(CONSTANTS.ALERT_TYPES).includes(s),
      required: true,
    },
    value: { type: String, default: null }
  },
  data() {
    return {
      ICON_BY_TYPE,
    };
  },
  computed: {}
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_vars.scss';

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}
.alert__icon {
  display: block;
  flex-shrink: 0;
  fill: var(--fillColor);
}
.alert__text {
  margin: 0 0 0 4px;
  display: block;
  flex-grow: 1;
  font-size: 14px;
  color: var(--fillColor);
  opacity: 1;
}

.alert--error {
  background-color: $color-red;
}
</style>
