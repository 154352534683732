
import FormInput from '@/components/FormInput.vue';
import Alert from '@/components/Alert.vue';
import Icon from '@/components/Icon.vue'
import { computed, defineComponent, ref, readonly } from 'vue';
import { useMobileDetection } from 'vue3-mobile-detection'
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
	signInWithRedirect,
	getRedirectResult,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider } from "firebase/auth";
import { useStore } from 'vuex';
import { TLoginState } from "@/types/user";
import CONSTANTS from '@/utils/constants';
import { useRouter } from 'vue-router'

const errs: {[key: string]: string} = {
	// sign-in with email and password
	'auth/invalid-email': 'The email address is not valid',
	'auth/user-disabled': 'The user corresponding to this email has been disabled',
	'auth/user-not-found': 'There is no user corresponding to this email',
	'auth/wrong-password': 'The password is invalid, or the account does not have a password set',
	// sign-in with popup
	'auth/account-exists-with-different-credential': 'There already exists an account with this email address',
	'auth/auth-domain-config-required': 'Configuration is not provided',
	'auth/cancelled-popup-request': 'Successive popup operations are triggered. Only one popup request is allowed at one time. All the popups would fail with this error except for the last one',
	'auth/operation-not-allowed': 'The type of account corresponding to the credential is not enabled',
	'auth/operation-not-supported-in-this-environment': 'This operation is not supported',
	'auth/popup-blocked': 'The popup was blocked by the browser',
	'auth/popup-closed-by-user': 'The popup window is closed by the user without completing the sign in',
	'auth/unauthorized-domain': 'The app domain is not authorized for OAuth operations',
	// 2FA error codes
	"totpNotValid": "One-time 2FA token is invalid or expired",
	"ctNotCreated": "Unable to issue custom login token"
}

type TSocials = 'google' | 'facebook' | 'twitter' | 'apple'

export default defineComponent({
  name: 'SignIn',
  components: { FormInput, Alert, Icon },

  setup () {
    const auth = getAuth()
    const store = useStore()
    const router = useRouter()

    const busy = ref<boolean>(false)
    const errorMessage = ref('')
    const email = ref('')
    const totp = ref('')
    const password = ref('')
    const ALERT_TYPES = readonly(CONSTANTS.ALERT_TYPES)

    const SOCIALS : {[K in TSocials]?: string} = {
      google: 'Google',
      facebook: 'Facebook',
      // twitter: 'Twitter',
      apple: 'AppleId',
    }

    const signIn = () => {
      setLoginState("pending")
      signInWithEmailAndPassword(auth, email.value, password.value)
      .then(() => {
        errorMessage.value = ''
        console.log('Successfully logged in!');
        router.push('/')
      })
      .catch((error) => {
        setLoginState("logout")
        console.log(error.code)
        errorMessage.value = errs[error.code] || error.message
      });
    }

    const signInWithTotp = () => {
      if (totp.value) {
        busy.value = true
        store.dispatch('user/loginWithCustomToken', totp.value)
        .then((state) => {
          console.log('state', state)
          router.push('/')
        })
        .catch((error) => {
          console.log(error.message)
          errorMessage.value = errs[error.message]
        })
        .finally(() => {
          busy.value = false
        })
      }
    }

    const signOut = () => {
      auth.signOut()
      console.log('Successfully logged out!');
    }

    const loginState = computed<TLoginState>(() => store.state.user.loginState)

    const isBusy = computed<boolean>(() => loginState.value === "pending" || busy.value)

    const setLoginState = (state: TLoginState) => {
      store.commit('user/setLoginState', state)
    }

    const onSocialLogin = (social: TSocials) => {

      let provider

      switch (social) {
        case "google":
          provider = new GoogleAuthProvider()
          provider.addScope('profile');
          provider.addScope('email');
          provider.setCustomParameters({ prompt: 'select_account' })
          break
        case "facebook":
          provider = new FacebookAuthProvider()
          break
        case "apple":
          provider = new OAuthProvider('apple.com')
          provider.addScope('email')
          provider.addScope('name')
          provider.setCustomParameters({ locale: 'en' })
          break
        case "twitter":
          provider = new TwitterAuthProvider()
          break
      }

      if (provider) {
        errorMessage.value = ''
        setLoginState("pending")
				if (useMobileDetection().isMobile()) {
					signInWithRedirect(auth, provider)
				} else {
					signInWithPopup(auth, provider)
					.then(() => {
						router.push('/')
						// console.log('user', user.user)
					})
					.catch((error) => {
						setLoginState("logout")
						errorMessage.value = errs[error.code] || error.message
					})
				}
			} else {
        errorMessage.value = `${SOCIALS[social]} auth provider is not supported yet`
      }

      // console.log(social)

      return
    }

		if (useMobileDetection().isMobile()) {
				setLoginState("pending")
				getRedirectResult(auth)
				.then((cred) => {
					if (!cred) {
						setLoginState("logout")
					} else {
						router.push('/')
					}
				}).catch((error) => {
					setLoginState("logout")
					errorMessage.value = errs[error.code] || error.message
				});
		}

    return { errorMessage, email, totp, password, busy, signIn, signInWithTotp, signOut, loginState, isBusy, onSocialLogin, SOCIALS, ALERT_TYPES }
  },
})

